'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/form/activity/page',
  // 属性新增
  activitySave(code, data) {
    return http.post('/form/activityCenter', data, { code });
  },
  // 属性编辑
  updateActivityCenter(code, data) {
    return http.put('/form/activityCenter', data, { code });
  },

  add(code, data) {
    return http.post('/form/activity', data, { code });
  },
  update(code, data) {
    return http.put('/form/activity', data, { code });
  },
  getClone(code, params) {
    return http.get('/form/activity/clone', { params, code });
  },
  delete(code, params) {
    return http.delete('/form/activity', { params, code });
  },
  // 查询活动内容设计
  getActivityCenter(code, params) {
    return http.get('/form/activityCenter/byActivityId', { params, code });
  },
  // 查询活动内容设计
  getEdit(code, id) {
    return http.get(`/form/activity/${id}`, { code });
  },

  // 活动内容发布
  updatePuhStatus(code, data) {
    return http.put('form/activity/updatePuhStatus', data, { code });
  },
  //  关联会议
  queryCampaignList(code, params) {
    return http.get('/issmart-campaign/campaign/queryCampaignList', {
      params,
      code,
    });
  },

  //  嵌入表单
  formList(code, params) {
    return http.get('/form/form/formList', {
      params,
      code,
    });
  },
  //   嵌入问卷
  questionnaireList(code, params) {
    return http.get('/questionnaire/questionnaire/questionnaireList', {
      params,
      code,
    });
  },
};
