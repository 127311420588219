'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/issmart-user/websiteUser/page',
  importWebsiteUserUrl: '/issmart-user/websiteUser/importUser',
  CompanyRelationShipTreeUrl: '/authority/dictionaryItem/code/tree', // 获取 与本公司关系 tree接口
  update(code, data) {
    return http.put('/issmart-user/websiteUser', data, { code });
  },
  delete(code, params) {
    return http.post('/issmart-user/websiteUser/delete', [...params.ids], {
      code,
    });
  },
  downloadWebsiteUserTemplate(code) {
    return http.get('/issmart-user/websiteUser/getTemplate', {
      responseType: 'blob',
      code,
    });
  },
  importWebsiteUser(code) {
    return http.get('/issmart-user/websiteUser/importUser', {
      responseType: 'blob',
      code,
    });
  },

  getImportWebsiteUserData(code, params) {
    return http.get('/issmart-user/websiteUser/getImportResult', {
      params,
      code,
    });
  },

  export(code, params) {
    return http.post('/issmart-user/websiteUser/exportUser', params, {
      responseType: 'blob',
      code,
    });
  },
  exportAll(code, params) {
    return http.post('/issmart-user/websiteUser/exportAll', params, {
      responseType: 'blob',
      code,
    });
  },
  // delteTag(code, params) {
  //   return http.delete('/account/contactTag/deleteByTagCode', { code, params });
  // },
  // 获取省市，行业接口
  getItemTree(code, params) {
    return http.get('/authority/dictionaryItem/code/tree', { params, code });
  },
};
