'use strict';

import http from '@/utils/http';

export default {
  upload(data) {
    return http.post(
      '/file/attachment/upload?bizType=GOODS_IMAGE&isSingle=true',
      data
    );
  },
};
