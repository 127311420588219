<template>
  <div class="ta-center">
    <a-button @click="handleClickByAdd">
      <template #icon><PlusOutlined /></template>
      添加议程
    </a-button>
  </div>
  <draggable
    :list="groupList"
    ghost-class="ghost"
    tag="transition-group"
    handle=".iss-answer-handle"
    :component-data="{ name: 'flip-list', type: 'transition' }"
    item-key="id"
  >
    <template #item="{ element, index }">
      <div class="group-item">
        <div class="group-title">
          <div class="left">议程{{ index + 1 }}</div>
          <div class="right">
            <DeleteTwoTone
              twoToneColor="#f5222d"
              class="item-delete"
              @click="handleClickByDelete(index)"
            />
            <down-outlined
              class="ml-10"
              @click="handleDown(index)"
              v-if="!element.elState"
            />
            <up-outlined
              class="ml-10"
              @click="handleDown(index, element.elState)"
              v-else
            />
          </div>
        </div>
        <div v-show="!element.elState">
          <a-form-item
            :name="[
              'questionnaireField',
              'questionAnswerVoList',
              index,
              'startTime',
            ]"
            label=""
            :rules="[required]"
          >
            <a-time-picker
              class="iss-datepicker"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
              v-model:value="element.startTime"
              value-format="HH:mm"
              placeholder="请选择开始时间"
              style="width: 100%"
              @change="handleTime(element)"
            />
          </a-form-item>

          <a-form-item
            :name="[
              'questionnaireField',
              'questionAnswerVoList',
              index,
              'endTime',
            ]"
            label=""
            :rules="[required]"
          >
            <a-time-picker
              class="iss-datepicker"
              v-model:value="element.endTime"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
              value-format="HH:mm"
              placeholder="请选择结束时间"
              style="width: 100%"
              @change="handleTime(element)"
            />
          </a-form-item>
          <a-form-item
            :name="[
              'questionnaireField',
              'questionAnswerVoList',
              index,
              'editorValue',
            ]"
            label="议程内容"
            :rules="[required, { max: 200, message: '长度不能超过200个字符' }]"
          >
            <div class="main-content">
              <!-- 放置富文本编辑器 -->
              <div class="main-ckeditor">
                <TinyEditor
                  ref="editorRef"
                  v-model:value="element.editorValue"
                  v-model:id="element.id"
                ></TinyEditor>
              </div>
            </div>
          </a-form-item>
          <!--        子议题   -->
          <!--          <a-form-item>-->
          <!--            <iss-child-group-->
          <!--              :root-name="element.id"-->
          <!--              :list="element.childGroup"-->
          <!--              :ruleIndex="index"-->
          <!--            >-->
          <!--            </iss-child-group>-->
          <!--          </a-form-item>-->

          <!--          <a-form-item-->
          <!--            :name="[-->
          <!--              'questionnaireField',-->
          <!--              'questionAnswerVoList',-->
          <!--              index,-->
          <!--              'description',-->
          <!--            ]"-->
          <!--            label=""-->
          <!--            :rules="[required, { max: 200, message: '长度不能超过200个字符' }]"-->
          <!--          >-->
          <!--            <a-textarea-->
          <!--              v-model:value="element.description"-->
          <!--              placeholder="请输入演讲主题"-->
          <!--              show-count-->
          <!--              :maxlength="200"-->
          <!--              @change="handleChange(element)"-->
          <!--            />-->
          <!--          </a-form-item>-->
          <!--          <a-form-item-->
          <!--            :name="[-->
          <!--              'questionnaireField',-->
          <!--              'questionAnswerVoList',-->
          <!--              index,-->
          <!--              'name',-->
          <!--            ]"-->
          <!--            label=""-->
          <!--            :rules="[{ max: 200, message: '长度不能超过200个字符' }]"-->
          <!--          >-->
          <!--            <a-input-->
          <!--              v-model:value="element.name"-->
          <!--              placeholder="请输入主讲人"-->
          <!--              @change="handleChange(element)"-->
          <!--            />-->
          <!--          </a-form-item>-->
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>
import { onBeforeUnmount, reactive, toRefs, watch, ref } from 'vue';
import { Form, TimePicker, message } from 'ant-design-vue';
import {
  PlusOutlined,
  DeleteTwoTone,
  DownOutlined,
  UpOutlined,
  // DragOutlined,
} from '@ant-design/icons-vue';
import { randoms } from '@/utils';
import draggable from 'vuedraggable';
import { useStore } from 'vuex';
// import IssChildGroup from '@/components/childGroup';
import TinyEditor from '@/components/myTinyEditor';

export default {
  name: 'AnswerGroup',
  components: {
    AFormItem: Form.Item,
    // ACheckbox: Checkbox,
    // ATooltip: Tooltip,
    PlusOutlined,
    DeleteTwoTone,
    DownOutlined,
    ATimePicker: TimePicker,
    draggable,
    UpOutlined,
    // IssChildGroup,
    TinyEditor,
  },
  props: {
    rootName: String,
    list: { type: Array, default: () => [] },
  },
  setup(props) {
    const store = useStore();
    const editorRef = ref();
    const state = reactive({ groupList: props.list });
    const required = { required: true, whitespace: true, message: '不能为空' };

    watch(
      () => props.list,
      value => (state.groupList = value)
    );
    const findIndex = (source, index) => {
      for (let i = 0; i < source.length; i++) {
        if (i === index) {
          // debugger
          return i;
        }
      }
      return -1;
    };

    onBeforeUnmount(() => {
      editorRef?.value?.remove();
      // alert('beforeUnmount:editorPage');
    });

    return {
      ...toRefs(state),
      editorRef,
      required,
      handleTime: element => {
        // 处理议程实时显示
        store.state.common.questionnaireFields.forEach(item => {
          if (item.id === store.state.common.selectedItem.id) {
            item.startTime = element.startTime;
            item.endTime = element.endTime;
            // item.name = element.name;
            // item.description = element.description;
          }
        });
      },
      // handleChange: element => {
      //   // 处理议程实时显示
      //   store.state.common.questionnaireFields.forEach(item => {
      //     if (item.id === store.state.common.selectedItem.id) {
      //       // item.name = element.name;
      //       // item.description = element.description;
      //     }
      //   });
      // },

      handleClickByDelete: index => {
        if (state.groupList.length === 1) {
          return message.error('最少要有一个议程');
        } else {
          state.groupList.splice(index, 1);
        }
      },
      handleDown: (index, elState) => {
        if (!elState) {
          let i = findIndex(state.groupList, index);
          state.groupList[i].elState = true;
        } else {
          let i = findIndex(state.groupList, index);
          state.groupList[i].elState = false;
        }
      },
      handleClickByAdd: () => {
        state.groupList.push({
          id: `${props.rootName}_${randoms(4)}`,
          startTime: '',
          endTime: '',
          // childGroup: [],
          // description: '',
          // name: '',
          editorValue: '',
          elState: true,
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.group-item {
  //display: flex;
  .group-title {
    display: flex;
    justify-content: space-between;
    .left {
      width: 75%;
    }
    .right {
      width: 25%;
    }
  }
  .iss-answer-handle {
    margin: 5px 5px 0 0;
    font-size: 18px;
    cursor: pointer;
  }
  .item-checkbox {
    margin: 3px 0 0 7px;
  }
  .item-delete {
    margin: 5px 0 0 5px;
    font-size: 18px;
    cursor: pointer;
  }
}

.left::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.main-content {
  height: 100%;
  border-radius: 8px;
  box-shadow: @box-shadow-base;
}
.main-ckeditor {
  height: calc(100vh - 205px);
}
</style>
