<template>
  <div class="iss-right">
    <div class="fs-20 fw-500">属性设置</div>
    <a-form
      ref="formRef"
      class="right-form"
      layout="vertical"
      :model="form"
      :validateTrigger="['change', 'blur']"
    >
      <!--   顶部控制内容    -->
      <div
        v-if="$store.state.common.selectedItem?.componentType === 'TOPCONTROL'"
      >
        <a-form-item
          :name="['questionnaireField', 'logo']"
          label="主办方LOGO"
          extra="建议图片上传宽高比建议1:1，大小不超过1M，格式为JPG、JPEG或PNG"
          v-bind="validateInfos.logo"
        >
          <iss-image-upload
            v-model:value="form.questionnaireField.logo"
            list-type="picture-card"
            :limit-size="1"
            accept=".jpe,.jpeg,.jpg,.png"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'topic']"
          label="主题"
          v-bind="validateInfos.topic"
        >
          <a-input
            v-model:value="form.questionnaireField.topic"
            placeholder="请输入主题内容"
          />
        </a-form-item>
        <!--        <a-form-item-->
        <!--          :name="['questionnaireField', 'buttonText']"-->
        <!--          label="按钮文字"-->
        <!--          v-bind="validateInfos.buttonText"-->
        <!--        >-->
        <!--          <a-input-->
        <!--            v-model:value="form.questionnaireField.buttonText"-->
        <!--            placeholder="请输入按钮文字"-->
        <!--          />-->
        <!--        </a-form-item>-->
        <!--        <a-form-item-->
        <!--          :name="['questionnaireField', 'buttonLink']"-->
        <!--          label="按钮跳转"-->
        <!--          v-bind="validateInfos.buttonLink"-->
        <!--        >-->
        <!--          <a-input-->
        <!--            v-model:value="form.questionnaireField.buttonLink"-->
        <!--            placeholder="请输入跳转链接"-->
        <!--          />-->
        <!--        </a-form-item>-->
        <a-form-item
          :name="['questionnaireField', 'themeColor']"
          label="按钮配色"
          v-bind="validateInfos.themeColor"
        >
          <div class="backColor">
            <div class="backColorText fs-16">背景</div>
            <input type="color" v-model="form.questionnaireField.themeColor" />
          </div>
        </a-form-item>

        <a-form-item
          :name="['questionnaireField', 'textColor']"
          label=""
          v-bind="validateInfos.textColor"
        >
          <div class="backColor">
            <div class="backColorText fs-16">文字</div>
            <input type="color" v-model="form.questionnaireField.textColor" />
          </div>
        </a-form-item>
      </div>
      <!--   横幅图属性设置   -->
      <div
        v-else-if="$store.state.common.selectedItem?.componentType === 'BANNER'"
      >
        <!--   横幅图和横幅视频 至少需要上传一个  bannerChart -->

        <a-form-item>
          <div class="tips">
            <ExclamationCircleOutlined
              class="icon"
            />请注意图片和视频只可上传一种
          </div>
        </a-form-item>
        <a-form-item
          :name="['bannerChart', 'banner']"
          label="横幅图"
          extra="建议图片上传宽高比为16:9，大小不超过1M，格式为JPG、JPEG或PNG"
        >
          <iss-image-upload
            v-model:value="form.bannerChart.banner"
            list-type="picture-card"
            :limit-size="1"
            accept=".jpe,.jpeg,.jpg,.png"
          />
        </a-form-item>

        <a-form-item
          :name="['bannerChart', 'bannerLink']"
          label="横幅视频"
          v-bind="validateInfos.bannerLink"
        >
          <a-input
            v-model:value="form.bannerChart.bannerLink"
            placeholder="请输入以http://开头的链接"
          />
        </a-form-item>

        <!--   有横幅视频就需要上传视频封面   -->
        <a-form-item
          :name="['bannerChart', 'videoBanner']"
          label="视频封面"
          extra="图片上传格式为JPG、JPEG或PNG，建议尺寸大小与视频保持一致"
        >
          <iss-image-upload
            v-model:value="form.bannerChart.videoBanner"
            list-type="picture-card"
            :limit-size="1"
            accept=".jpe,.jpeg,.jpg,.png"
          />
        </a-form-item>
      </div>
      <!--   标题设置   -->
      <!--    -->
      <!--      <div-->
      <!--        v-else-if="$store.state.common.selectedItem?.componentType === 'TITLE'"-->
      <!--      >-->
      <!--        <a-form-item-->
      <!--          :name="['questionnaire', 'activityTitle']"-->
      <!--          label="标题"-->
      <!--          v-bind="validateInfos.activityTitle"-->
      <!--        >-->
      <!--          <a-input-->
      <!--            v-model:value="form.questionnaire.activityTitle"-->
      <!--            placeholder="请输入标题"-->
      <!--          />-->
      <!--        </a-form-item>-->
      <!--        <a-form-item-->
      <!--          :name="['questionnaire', 'description']"-->
      <!--          label="描述"-->
      <!--          v-bind="validateInfos.description"-->
      <!--        >-->
      <!--          <a-textarea-->
      <!--            v-model:value="form.questionnaire.description"-->
      <!--            placeholder="请输入描述"-->
      <!--            show-count-->
      <!--            :maxlength="150"-->
      <!--          />-->
      <!--        </a-form-item>-->
      <!--      </div>-->
      <!--   举办时间  分开始时间和结算时间 -->
      <div
        v-else-if="$store.state.common.selectedItem?.componentType === 'TIME'"
      >
        <a-form-item
          :name="['questionnaireField', 'startTime']"
          label="举办时间"
          v-bind="validateInfos.startTime"
        >
          <a-date-picker
            class="iss-datepicker"
            v-model:value="form.questionnaireField.startTime"
            :getCalendarContainer="triggerNode => triggerNode.parentNode"
            show-time
            value-format="yyyy-MM-DD HH:mm"
            placeholder="请选择开始时间"
            style="width: 100%"
            @change="handleTime"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'endTime']"
          label=""
          v-bind="validateInfos.endTime"
        >
          <a-date-picker
            class="iss-datepicker"
            v-model:value="form.questionnaireField.endTime"
            :getCalendarContainer="triggerNode => triggerNode.parentNode"
            show-time
            value-format="yyyy-MM-DD HH:mm"
            placeholder="请选择结束时间"
            style="width: 100%"
            @change="handleTime"
          />
        </a-form-item>
      </div>
      <!--     地址属性 省 级 市  -->
      <!--  v-else-if="$store.state.common.selectedItem.questionnaireId"    -->
      <div
        v-else-if="
          $store.state.common.selectedItem?.componentType === 'ADDRESS'
        "
      >
        <a-form-item
          label="省"
          :name="['questionnaireField', 'province']"
          v-bind="validateInfos.province"
        >
          <a-select
            v-model:value="form.questionnaireField.province"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in provinceList"
              :key="item.itemNameCn"
              :value="item.itemNameCn"
              @click="handleClickByUrlType(item)"
            >
              {{ item.itemNameCn }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="市"
          :name="['questionnaireField', 'city']"
          v-bind="validateInfos.city"
        >
          <a-select
            v-model:value="form.questionnaireField.city"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in cityList"
              :key="item.itemNameCn"
              :value="item.itemNameCn"
            >
              {{ item.itemNameCn }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :name="['questionnaireField', 'address']"
          label="详细地址"
          v-bind="validateInfos.address"
        >
          <a-textarea
            v-model:value="form.questionnaireField.address"
            placeholder="请选择省市，然后输入详细地址"
            show-count
            :maxlength="50"
          />
        </a-form-item>
      </div>
      <div
        v-else-if="$store.state.common.selectedItem?.componentType === 'TEXT'"
      >
        <a-form-item
          :name="['questionnaireField', 'fieldTips']"
          label="文本标题"
          v-bind="validateInfos.fieldTips"
        >
          <a-input
            v-model:value="form.questionnaireField.fieldTips"
            placeholder="请输入标题名称"
            @change="handleChange($store.state.common.selectedItem, 'TEXT')"
          />
        </a-form-item>
        <!--       这里是富文本编辑器  -->
        <a-form-item
          :name="['questionnaireField', 'editorValue']"
          label="文本内容"
          v-bind="validateInfos.editorValue"
        >
          <div class="main-content">
            <!-- 放置富文本编辑器 -->
            <div class="main-ckeditor">
              <!--             v-model:value="editorValue"  -->
              <TinyEditor
                ref="editorRef"
                v-model:value="form.questionnaireField.editorValue"
                @fnOk="handleFnOkByEditor"
              ></TinyEditor>
            </div>
            <!--          <div-->
            <!--            class="ck-content notShow"-->
            <!--            ref="showEditorRef"-->
            <!--            v-html="editorData"-->
            <!--          ></div>-->
            <!--      左侧按钮操作区    -->
            <!--          <div class="main-button">-->
            <!--            <a-button @click="handleClickCancel"> 取消 </a-button>-->
            <!--            <a-button type="primary" @click="handleClickSave"> 保存 </a-button>-->
            <!--          </div>-->
          </div>
        </a-form-item>
      </div>

      <!--    添加嘉宾 -->

      <div
        v-else-if="$store.state.common.selectedItem?.componentType === 'ANGER'"
      >
        <a-form-item
          :name="['questionnaireField', 'angerTitle']"
          label="标题名称"
          v-bind="validateInfos.agendaTitle"
        >
          <a-input
            v-model:value="form.questionnaireField.agendaTitle"
            placeholder="请输入嘉宾标题名称"
            @change="handleChange($store.state.common.selectedItem, 'ANGER')"
          />
        </a-form-item>
        <a-form-item label="">
          <iss-guests-group
            :root-name="form.questionnaireField.fieldCode"
            :list="form.questionnaireField.questionAnswerVoList"
          />
        </a-form-item>
      </div>

      <!--    添加议程 详情模块都存储在 questionnaireField {}  -->

      <div
        v-else-if="$store.state.common.selectedItem?.componentType === 'AGENDA'"
      >
        <a-form-item
          :name="['questionnaireField', 'agendaTitle']"
          label="议程标题"
          v-bind="validateInfos.agendaTitle"
        >
          <a-input
            v-model:value="form.questionnaireField.agendaTitle"
            placeholder="请输入议程标题"
            @change="handleChange($store.state.common.selectedItem, 'AGENDA')"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'agendaName']"
          label="议程名称"
          v-bind="validateInfos.agendaName"
        >
          <a-input
            v-model:value="form.questionnaireField.agendaName"
            placeholder="请输入议程名称"
            @change="handleChange($store.state.common.selectedItem, 'AGENDA')"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'agendaDate']"
          label="议程日期"
          v-bind="validateInfos.agendaDate"
        >
          <a-date-picker
            class="iss-datepicker"
            v-model:value="form.questionnaireField.agendaDate"
            :getCalendarContainer="triggerNode => triggerNode.parentNode"
            value-format="yyyy-MM-DD"
            placeholder="请选择议程日期"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="议程内容" required>
          <iss-group
            :root-name="form.questionnaireField.fieldCode"
            :list="form.questionnaireField.questionAnswerVoList"
          />
        </a-form-item>
      </div>

      <!--     添加图片 详情模块都存储在 questionnaireField {}  -->
      <div
        v-else-if="$store.state.common.selectedItem?.componentType === 'IMAGE'"
      >
        <a-form-item
          :name="['questionnaireField', 'imageTitle']"
          l
          abel="图片标题"
          v-bind="validateInfos.imageTitle"
        >
          <a-input
            v-model:value="form.questionnaireField.imageTitle"
            placeholder="请输入图片标题"
            @change="handleChange($store.state.common.selectedItem, 'IMAGE')"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'image']"
          label="图片"
          extra="图片上传格式为JPG、JPE、JPEG或PNG，支持长图上传大小不可超过2M"
          v-bind="validateInfos.image"
        >
          <iss-image-upload
            v-model:value="form.questionnaireField.image"
            list-type="picture-card"
            :limit-size="2"
            accept=".jpe,.jpeg,.jpg,.png"
            @change="handleChange($store.state.common.selectedItem, 'IMAGE')"
          />
        </a-form-item>
      </div>

      <!--    添加视频    -->
      <div
        v-else-if="$store.state.common.selectedItem?.componentType === 'VIDEO'"
      >
        <a-form-item
          :name="['questionnaireField', 'videoTitle']"
          label="视频标题"
          v-bind="validateInfos.videoTitle"
        >
          <a-input
            v-model:value="form.questionnaireField.videoTitle"
            placeholder="请输入视频标题"
            @change="handleChange($store.state.common.selectedItem, 'VIDEO')"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'videoLink']"
          label="横幅视频"
          v-bind="validateInfos.videoLink"
        >
          <a-input
            v-model:value="form.questionnaireField.videoLink"
            placeholder="请输入视频链接"
            @change="handleChange($store.state.common.selectedItem, 'VIDEO')"
          />
        </a-form-item>

        <a-form-item
          :name="['questionnaireField', 'videoImage']"
          label="视频封面"
          extra="图片上传格式为JPG、JPEG或PNG，建议尺寸大小与视频保持一致"
          v-bind="validateInfos.videoImage"
        >
          <iss-image-upload
            v-model:value="form.questionnaireField.videoImage"
            list-type="picture-card"
            accept=".jpe,.jpeg,.jpg,.png"
            @change="handleChange($store.state.common.selectedItem, 'VIDEO')"
          />
        </a-form-item>

        <a-form-item
          :name="['questionnaireField', 'videoDescription']"
          label="视频说明"
          v-bind="validateInfos.videoDescription"
        >
          <a-textarea
            v-model:value="form.questionnaireField.videoDescription"
            placeholder="请输入视频说明"
            show-count
            :maxlength="150"
          />
        </a-form-item>
      </div>
      <!--    表单   -->
      <div v-if="$store.state.common.selectedItem?.componentType === 'FORM'">
        <a-form-item
          :name="['questionnaireField', 'formTitle']"
          label="表单标题"
          v-bind="validateInfos.formTitle"
        >
          <a-input
            v-model:value="form.questionnaireField.formTitle"
            placeholder="请输入标题"
          />
        </a-form-item>

        <a-form-item label="关联会议" v-bind="validateInfos.campaignName">
          <a-select
            v-model:value="form.questionnaireField.campaignName"
            show-search
            placeholder="请选择"
            :filter-option="
              (inputValue, option) => handleFilterOption(inputValue, option)
            "
            @change="($event, option) => handleSelect($event, option, 'FORM')"
          >
            <a-select-option
              v-for="item in meetingList"
              :key="item"
              :value="item.id"
            >
              {{ item.campaignName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="嵌入表单"
          :name="['questionnaireField', 'formMeeting']"
          v-bind="validateInfos.formMeeting"
        >
          <a-select
            v-model:value="form.questionnaireField.formMeeting"
            placeholder="请选择"
            @change="
              ($event, option) => handleSelectMeeting($event, option, 'FORM')
            "
          >
            <a-select-option
              v-for="item in formList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.formName }}
            </a-select-option>
          </a-select>
          <div class="creatFormTips">
            嵌入时请确保表单为正常状态，若过期或被删除；客户端将不展示表单。
          </div>

          <!--     需要做判断， formList 长度是0时展示这个     -->
          <div v-if="form.questionnaireField.campaignName">
            <a-button class="creatForm" type="primary" @click="handleCreatForm">
              去创建表单
            </a-button>
            <div class="creatFormTips">
              该会议下无表单可嵌入，请先去创建表单
            </div>
          </div>
        </a-form-item>

        <!--        <a-form-item-->
        <!--          :name="['questionnaireField', 'formBannerStatus']"-->
        <!--          label="是否展示表单横幅"-->
        <!--          v-bind="validateInfos.formBannerStatus"-->
        <!--        >-->
        <!--          <a-switch-->
        <!--            v-model:checked="form.questionnaireField.formBannerStatus"-->
        <!--            checked-children="是"-->
        <!--            un-checked-children="否"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <!--  需要根据嵌入表单的数组来判断是否显示   formList -->
        <div v-if="form.questionnaireField.formMeeting">
          <a-form-item
            :name="['questionnaireField', 'formBtnText']"
            label="按钮文字"
            v-bind="validateInfos.formBtnText"
          >
            <a-input
              v-model:value="form.questionnaireField.formBtnText"
              placeholder="请输入按钮文字"
            />
          </a-form-item>

          <a-form-item
            :name="['questionnaireField', 'formThemeStyleBac']"
            label="按钮配色"
            v-bind="validateInfos.formThemeStyleBac"
          >
            <div class="backColor">
              <div class="backColorText fs-16">背景</div>
              <input
                type="color"
                v-model="form.questionnaireField.formThemeStyleBac"
              />
            </div>
          </a-form-item>

          <a-form-item
            :name="['questionnaireField', 'formTextStyleColor']"
            label=""
            v-bind="validateInfos.formTextStyleColor"
          >
            <div class="backColor">
              <div class="backColorText fs-16">文字</div>
              <input
                type="color"
                v-model="form.questionnaireField.formTextStyleColor"
              />
            </div>
          </a-form-item>
        </div>
      </div>

      <!--    问卷   -->
      <div
        v-if="
          $store.state.common.selectedItem?.componentType === 'QUESTIONNAIRE'
        "
      >
        <a-form-item
          :name="['questionnaireField', 'qTitle']"
          label="问卷标题"
          v-bind="validateInfos.qTitle"
        >
          <a-input
            v-model:value="form.questionnaireField.qTitle"
            placeholder="请输入标题"
          />
        </a-form-item>

        <a-form-item label="关联会议" v-bind="validateInfos.QcampaignName">
          <a-select
            v-model:value="form.questionnaireField.QcampaignName"
            show-search
            placeholder="请选择"
            :filter-option="
              (inputValue, option) => handleFilterOption(inputValue, option)
            "
            @change="
              ($event, option) => handleSelect($event, option, 'QUESTIONNAIRE')
            "
          >
            <a-select-option
              v-for="item in meetingList"
              :key="item"
              :value="item.id"
            >
              {{ item.campaignName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="嵌入问卷"
          :name="['questionnaireField', 'qMeeting']"
          v-bind="validateInfos.qMeeting"
        >
          <a-select
            v-model:value="form.questionnaireField.qMeeting"
            placeholder="请选择"
            @change="
              ($event, option) =>
                handleSelectMeeting($event, option, 'QUESTIONNAIRE')
            "
          >
            <a-select-option
              v-for="item in questionnaireList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.questionnaireName }}
            </a-select-option>
          </a-select>
          <div class="creatFormTips">
            嵌入时请确保问卷为正常状态，若过期或被删除；客户端将不展示问卷。
          </div>

          <div v-if="form.questionnaireField.QcampaignName">
            <a-button
              class="creatForm"
              type="primary"
              @click="handleCreatQuestionnaire"
            >
              去创建问卷
            </a-button>
            <div class="creatFormTips">
              该会议下无问卷可嵌入，请先去创建问卷
            </div>
          </div>
        </a-form-item>
        <!--   需要根据嵌入问卷的数组来判断是否显示   questionnaireList -->
        <!--        <a-form-item-->
        <!--          :name="['questionnaireField', 'qBannerStatus']"-->
        <!--          label="是否展示问卷横幅"-->
        <!--          v-bind="validateInfos.qBannerStatus"-->
        <!--        >-->
        <!--          <a-switch-->
        <!--            v-model:checked="form.questionnaireField.qBannerStatus"-->
        <!--            checked-children="是"-->
        <!--            un-checked-children="否"-->
        <!--          />-->
        <!--        </a-form-item>-->

        <div v-if="form.questionnaireField.qMeeting">
          <a-form-item
            :name="['questionnaireField', 'qBtnText']"
            label="按钮文字"
            v-bind="validateInfos.qBtnText"
          >
            <a-input
              v-model:value="form.questionnaireField.qBtnText"
              placeholder="请输入按钮文字"
            />
          </a-form-item>

          <a-form-item
            :name="['questionnaireField', 'qThemeStyleBac']"
            label="按钮配色"
            v-bind="validateInfos.qThemeStyleBac"
          >
            <div class="backColor">
              <div class="backColorText fs-16">背景</div>
              <input
                type="color"
                v-model="form.questionnaireField.qThemeStyleBac"
              />
            </div>
          </a-form-item>

          <a-form-item
            :name="['questionnaireField', 'qTextStyleColor']"
            label=""
            v-bind="validateInfos.qTextStyleColor"
          >
            <div class="backColor">
              <div class="backColorText fs-16">文字</div>
              <input
                type="color"
                v-model="form.questionnaireField.qTextStyleColor"
              />
            </div>
          </a-form-item>
        </div>
      </div>

      <!--     底部控件设置  -->
      <div
        v-if="
          $store.state.common.selectedItem?.componentType === 'BOTTOMCONTROL'
        "
      >
        <a-form-item
          :name="['questionnaireField', 'themeColorBut']"
          label="样式一"
          v-bind="validateInfos.themeColorBut"
        >
          <div class="iss-but" @click="handleBut('butStyleOne')">
            <a-button type="primary" class="but">按钮</a-button>
          </div>
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'themeColorButTwo']"
          label="样式二"
          v-bind="validateInfos.themeColorButTwo"
        >
          <div class="iss-but2" @click="handleBut('butStyleTwo')">
            <a-button class="but">分享</a-button>
            <a-button type="primary" class="but">按钮</a-button>
          </div>
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'butStyleText']"
          label="按钮文字"
          v-bind="validateInfos.butStyleText"
        >
          <a-input
            v-model:value="form.questionnaireField.butStyleText"
            placeholder="请输入按钮文字"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'butStyleLine']"
          label="按钮跳转"
          v-bind="validateInfos.butStyleLine"
        >
          <a-input
            v-model:value="form.questionnaireField.butStyleLine"
            placeholder="请输入跳转链接"
          />
        </a-form-item>
        <a-form-item
          :name="['questionnaireField', 'themeStyleBac']"
          label="按钮配色"
          v-bind="validateInfos.themeStyleBac"
        >
          <div class="backColor">
            <div class="backColorText fs-16">背景</div>
            <input
              type="color"
              v-model="form.questionnaireField.themeStyleBac"
            />
          </div>
        </a-form-item>

        <a-form-item
          :name="['questionnaireField', 'textStyleColor']"
          label=""
          v-bind="validateInfos.textStyleColor"
        >
          <div class="backColor">
            <div class="backColorText fs-16">文字</div>
            <input
              type="color"
              v-model="form.questionnaireField.textStyleColor"
            />
          </div>
        </a-form-item>
      </div>
    </a-form>

    <a-button type="primary" size="middle" @click="handleSave"> 保存 </a-button>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch, onBeforeUnmount, computed } from 'vue';
import { useStore } from 'vuex';
import TinyEditor from '@/components/TinyEditor';

import {
  // Col,
  Form,
  DatePicker,
  message,
  Select,
  //Switch,
} from 'ant-design-vue';
import IssImageUpload from '@/components/imageUpload';
import websiteUserApi from '@/api/websiteUser';
import activityCenterApi from '@/api/activityCenter';
import IssGroup from '@/components/group';
import IssGuestsGroup from '@/components/guestsGroup';
// import { useRouter } from 'vue-router';
import { validHttp } from '@/utils/validation';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { local } from '@/utils/storage';

export default {
  name: 'attribute.vue',
  components: {
    // ASwitch: Switch,
    // IssImageUpload,
    // IssAnswerGroup,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    // ASwitch: Switch,
    IssImageUpload,
    ADatePicker: DatePicker,
    IssGuestsGroup,
    TinyEditor,
    IssGroup,
    ExclamationCircleOutlined,
  },
  setup() {
    const store = useStore();
    // const router = useRouter();
    const formRef = ref();
    const editorRef = ref();
    const attributeRef = ref();

    // {
    //   "id": 205,
    //   "fieldCode": "questionnaire",
    //   "fieldIcon": "FileUnknownOutlined",
    //   "fieldName": "嵌入问卷",
    //   "fieldType": "QUESTIONNAIRE",
    //   "componentType": "QUESTIONNAIRE",
    //   "captcha": false,
    //   "category": 1,
    //   "enabled": true,
    //   "qTitle": "",
    //   "qMeeting": "",
    //   "QcampaignName": "",
    //   "qBtnText": "提交",
    //   "qThemeStyleBac": "#5979f8",
    //   "qTextStyleColor": "#ffffff",
    //   "topic": "",
    //   "disabled": false
    // },

    // const { campaignId, eventId, onlyId } = session.get('update');

    const form = reactive({
      // 存储标题，designer 也是在这个里取值
      // questionnaire: {
      //   activityTitle: '',
      //   description: '',
      // },
      // 存储和横幅图属性
      bannerChart: {
        banner: '',
        bannerLink: '',
        videoBanner: '',
      },

      questionnaireField: {
        // 顶部
        logo: '',
        topic: '',
        // buttonText: '',
        // buttonLink: '',
        themeColor: '',
        textColor: '',
        // 举办时间
        startTime: '',
        endTime: '',
        //  省级市
        province: '',
        city: '',
        address: '',
        //  文本
        fieldTips: '',
        editorValue: '',
        //   议程
        agendaName: '',
        agendaTitle: '',
        agendaDate: '',
        // questionAnswerVoList: [
        //   {
        //     startTime: '',
        //     endTime: '',
        //     description: '',
        //     name: '',
        //   }
        // ],
        //   图片
        imageTitle: '',
        image: '',
        //  视频
        videoTitle: '',
        videoLink: '',
        videoImage: '',
        videoDescription: '',
        //   底部
        butStyleText: '',
        butStyleLine: '',
        themeStyleBac: '',
        textStyleColor: '',
        //  表单
        formTitle: '',
        formMeeting: null,
        campaignName: null,
        // formBannerStatus: false,
        formBtnText: '',
        formThemeStyleBac: '',
        formTextStyleColor: '',
        // 问卷
        qTitle: '',
        qMeeting: null,
        QcampaignName: null,
        // qBannerStatus: false,
        qBtnText: '',
        qThemeStyleBac: '',
        qTextStyleColor: '',
      },
    });
    const useForm = Form.useForm;
    const state = reactive({
      provinceList: [],
      cityList: [],
      componentType: '',
      meetingList: [],
      formList: [],
      questionnaireList: [],

      //   表单会议
      meetingForm: {},
      //  问卷会议
      meetingQuestionnaire: {},
      link: '',
    });

    const required = { required: true, whitespace: true, message: '不能为空' };
    const rulesRef = reactive({
      TOPCONTROL: {
        logo: [required],
        topic: [required, { max: 16, message: '长度不能超过16个字符' }],
        // buttonText: [required, { max: 4, message: '长度不能超过4个字符' }],
        // buttonLink: [
        //   required,
        //   {
        //     type: 'url',
        //     message: '请输入正确的URL地址，以http或者https开头的链接',
        //   },
        // ],
        themeColor: [required],
        textColor: [required],
      },
      BANNER: {
        bannerLink: [
          {
            type: 'url',
            message: '请输入正确的URL地址，以http或者https开头的链接',
          },
        ],
      },
      // TITLE: {
      //   activityTitle: [required, { max: 50, message: '不可超过50个字' }],
      //   description: [required, { max: 150, message: '不可超过150个字' }],
      // },
      TIME: {
        startTime: [required],
        endTime: [required],
      },
      ADDRESS: {
        province: [required],
        city: [required],
        address: [{ max: 50, message: '长度不能超过50个字符' }],
      },
      TEXT: {
        fieldTips: [{ max: 50, message: '长度不能超过50个字符' }],
        editorValue: [required],
      },
      AGENDA: {
        agendaName: [required, { max: 50, message: '长度不能超过50个字符' }],
        agendaTitle: [{ max: 50, message: '长度不能超过50个字符' }],
        agendaDate: [required],
      },
      ANGER: {
        angerTitle: [required, { max: 50, message: '长度不能超过50个字符' }],
      },
      IMAGE: {
        imageTitle: [{ max: 50, message: '长度不能超过50个字符' }],
        image: [required],
      },
      VIDEO: {
        videoTitle: [required, { max: 50, message: '长度不能超过50个字符' }],
        videoLink: [
          required,
          {
            type: 'url',
            message:
              '请输入视频清晰度在720P以上正确的URL地址，以http或者https开头的链接',
          },
        ],
        videoImage: [required],
        videoDescription: [{ max: 150, message: '长度不能超过150个字符' }],
      },
      BOTTOMCONTROL: {
        butStyleText: [required, { max: 4, message: '长度不能超过4个字符' }],
        butStyleLine: [
          required,
          {
            type: 'url',
            message: '请输入正确的URL地址，以http或者https开头的链接',
          },
        ],
        themeStyleBac: [required],
        textStyleColor: [required],
      },
      FORM: {
        formTitle: [required, { max: 50, message: '长度不能超过50个字符' }],
        formMeeting: [required],
        campaignName: [required],
        formBtnText: [{ max: 4, message: '长度不能超过4个字符' }],
        // formThemeStyleBac: [required],
        // formTextStyleColor: [required],
      },
      QUESTIONNAIRE: {
        qTitle: [required, { max: 50, message: '长度不能超过50个字符' }],
        qMeeting: [required],
        QcampaignName: [required],
        qBtnText: [{ max: 4, message: '长度不能超过4个字符' }],
        // qThemeStyleBac: [required],
        // qTextStyleColor: [required],
      },
    });

    const validator = computed(() => {
      return useForm(
        form.questionnaireField,
        rulesRef[state.componentType] || {}
      );
    });

    const validate = computed(() => {
      return validator.value.validate;
    });

    const validateInfos = computed(() => {
      return validator.value.validateInfos;
    });

    const getFormList = id => {
      activityCenterApi
        .formList('', {
          campaignId: id,
        })
        .then(res => {
          state.formList = res;
        });
    };

    const getLink = ({ id, eventId, campaignId }) => {
      state.link =
        `${process.env.VUE_APP_VUE_TO_FORM}` +
        `/#/marketing/activity/all/${id}/${eventId}/${campaignId}`;
    };

    const getQuestionnaireList = id => {
      activityCenterApi
        .questionnaireList('', {
          campaignId: id,
        })
        .then(res => {
          state.questionnaireList = res;
        });
    };

    watch(
      () => store.state.common.selectedItem,
      value => {
        state.componentType = value.componentType;
        form.questionnaireField = value;
        form.bannerChart = store.state.common.bannerChart;
        if (store.state.common.selectedItem.componentType === 'AGENDA') {
          let selectedItem = store.state.common.selectedItem;
          for (let i = 0; i < selectedItem.questionAnswerVoList.length; i++) {
            const element = selectedItem.questionAnswerVoList[i];
            const startTimeDate = new Date(
              `1970-01-01T${element.startTime}:00`
            );
            const endTimeDate = new Date(`1970-01-01T${element.endTime}:00`);

            if (endTimeDate < startTimeDate) {
              return message.error('议程结束时间不能小于或等于开始时间');
            }
          }
        }
      },
      { deep: true }
    );

    watch(
      () => form,
      value => {
        // 顶部控件按钮颜色配置
        document
          .getElementById('iss-form-btn')
          ?.style.setProperty(
            '--primary-color',
            form.questionnaireField.themeColor
          );
        document
          .getElementById('iss-form-btn')
          ?.style.setProperty('--color', form.questionnaireField.textColor);

        // 底部控件样式一按钮 底纹 配置
        document
          .getElementById('iss-themeStyleColor')
          ?.style.setProperty(
            '--primary-color',
            value.questionnaireField.themeStyleBac
          );
        // 底部控件 字体颜色 配置
        document
          .getElementById('iss-themeStyleColor')
          ?.style.setProperty(
            '--color',
            value.questionnaireField.textStyleColor
          );

        // 底部控件样式二按钮颜色配置
        document
          .getElementById('iss-butStyletTwo')
          ?.style.setProperty(
            '--primary-color',
            value.questionnaireField.themeStyleBac
          );

        // 字体颜色 配置
        document
          .getElementById('iss-butStyletTwo')
          ?.style.setProperty(
            '--color',
            value.questionnaireField.textStyleColor
          );

        // 底部分享按钮样式设置
        document
          .getElementById('iss-butStyletShare')
          ?.style.setProperty(
            '--primary-color',
            value.questionnaireField.themeStyleBac
          );
        document
          .getElementById('iss-butStyletShare')
          ?.style.setProperty(
            '--color',
            value.questionnaireField.textStyleColor
          );

        // 表单提交按钮样式设置
        document
          .getElementById('iss-formBtn')
          ?.style.setProperty(
            '--primary-color',
            value.questionnaireField.formThemeStyleBac
          );
        document
          .getElementById('iss-formBtn')
          ?.style.setProperty(
            '--color',
            value.questionnaireField.formTextStyleColor
          );

        // 问卷提交按钮样式设置

        document
          .getElementById('iss-questionnaireBtn')
          ?.style.setProperty(
            '--primary-color',
            value.questionnaireField.qThemeStyleBac
          );
        document
          .getElementById('iss-questionnaireBtn')
          ?.style.setProperty(
            '--color',
            value.questionnaireField.qTextStyleColor
          );

        const questionnaireFields = store.state.common.questionnaireFields;
        const item = questionnaireFields.find(
          i => i.fieldCode === value.questionnaireField.fieldCode
        );
        item && Object.assign(item, { ...value.questionnaireField });

        //  todo 处理嵌入表单下拉值回显
        for (let i = 0; i < questionnaireFields.length; i++) {
          let item = questionnaireFields[i];

          if (item.componentType === 'FORM' && item.campaignName) {
            getFormList(item.campaignId);
            const newlist = state.meetingList.filter(
              i => i.id === item.campaignName
            );
            getLink(newlist[0]);
          } else if (item.componentType === 'FORM' && !item.campaignName) {
            state.formList = [];
          }

          if (item.componentType === 'QUESTIONNAIRE' && item.QcampaignName) {
            getQuestionnaireList(item.campaignId);
            const newlist = state.meetingList.filter(
              i => i.id === item.campaignName
            );
            getLink(newlist[0]);
          } else if (item.componentType === 'FORM' && !item.QcampaignName) {
            state.questionnaireList = [];
          }
        }
      },
      { deep: true }
    );

    // 获取省市数据
    websiteUserApi.getItemTree('', { code: 'CHINA_AREA' }).then(data => {
      state.provinceList = data;
    });
    // 关联会议数据
    activityCenterApi.queryCampaignList('', {}).then(res => {
      state.meetingList = res;
    });

    // todo 获取表单数据
    watch(
      () => state.meetingForm.campaignId,
      value => {
        getFormList(value);
      }
    );

    watch(
      () => state.meetingQuestionnaire.campaignId,
      value => {
        // 获取问卷数据
        getQuestionnaireList(value);
      }
    );

    watch(
      () => form.questionnaireField.province,
      () => {
        form.questionnaireField.province &&
          (state.cityList = state.provinceList.find(
            i => i.itemCode === form.questionnaireField.province
          ).children);
      }
    );
    //
    watch(
      () => form.bannerChart,
      value => {
        if (value.banner && value.bannerLink) {
          return message.info('请注意图片和视频只可上传一种');
        }
        if (value.banner === '' && value.bannerLink === '') {
          return message.info('横幅图和横幅视频至少需要上传一个');
        }

        if (value.bannerLink) {
          if (!validHttp(value.bannerLink)) {
            return message.error(
              '请输入正确的横幅视频URL地址，以http或者https开头的链接'
            );
          }
        }
      },
      { deep: true }
    );

    const editorValue = computed({
      get: () => form.article,
      set: value => (form.article = value),
    });

    onBeforeUnmount(() => {
      editorRef?.value?.remove();
      // alert('beforeUnmount:editorPage');
    });

    return {
      form,
      formRef,
      editorRef,
      validateInfos,
      validate,
      attributeRef,
      ...toRefs(state),
      editorValue,
      required: { required: true, whitespace: true, message: '不能为空' },
      // 富文本的自定义事件
      handleFnOkByEditor: newValue => {
        form.questionnaireField.questionAnswerVoList.editorValue = newValue;

        store.state.common.questionnaireFields.forEach(item => {
          if (item.id === store.state.common.selectedItem.id) {
            item.editorValue = newValue;
            // item.editorValue = form.questionnaireField.questionAnswerVoList.editorValue
          }
        });
      },
      handleBut: butStyle => {
        if (butStyle === 'butStyleOne') {
          form.questionnaireField.butStyle = 1;
          form.questionnaireField.showBut = false;
        } else {
          form.questionnaireField.butStyle = 2;
          form.questionnaireField.showBut = true;
        }
      },

      // 表单模块关联会议
      handleSelect: (value, option, type) => {
        console.log(option);
        const newlist = state.meetingList.filter(item => item.id === value);
        if (type === 'FORM') {
          state.meetingForm = newlist[0];
          //  往FORM json里面添加 campaignId
          store.state.common.questionnaireFields.map(item => {
            if (item.componentType === 'FORM') {
              item.campaignId = newlist[0].campaignId;
              // item.formUrl = '';
            }
          });

          //  选择会议后，清空嵌入表单原有的内容
          form.questionnaireField.formMeeting = null;
        }

        if (type === 'QUESTIONNAIRE') {
          state.meetingQuestionnaire = newlist[0];
          //  往FORM json里面添加 campaignId
          store.state.common.questionnaireFields.map(item => {
            if (item.componentType === 'QUESTIONNAIRE') {
              item.campaignId = newlist[0].campaignId;
            }
          });

          //  选择会议后，清空嵌入问卷原有的内容
          form.questionnaireField.qMeeting = null;
        }
      },

      handleSelectMeeting: (value, option, type) => {
        if (type === 'FORM') {
          const list = state.formList.filter(item => item.id === value);
          local.set('formId', list[0].id);
          store.state.common.questionnaireFields.map(item => {
            if (
              item.componentType === 'FORM' &&
              item.formMeeting === list[0].id
            ) {
              item.formUrl = list[0].formUrl;
              item.fieldCount = list[0].fieldCount;
            }
          });
        }

        if (type === 'QUESTIONNAIRE') {
          const questionnaireList = state.questionnaireList.filter(
            item => item.id === value
          );
          store.state.common.questionnaireFields.map(item => {
            if (
              item.componentType === 'QUESTIONNAIRE' &&
              item.qMeeting === questionnaireList[0].id
            ) {
              item.questionnaireUrl = questionnaireList[0].questionnaireUrl;
              item.questionnaireStatus =
                questionnaireList[0].questionnaireStatus;
            }
          });
        }
      },

      // todo 跳转该会议下去创建表单 暂时写死
      handleCreatForm: () => {
        window.open(state.link, '_self');
      },
      handleCreatQuestionnaire: () => {
        // window.open(state.link, '_self');
      },
      // 处理上传图片视频后实时显示在 designer 文件中
      handleChange: (selectedItem, type) => {
        store.state.common.questionnaireFields.forEach(item => {
          if (item.id === selectedItem.id) {
            if (type === 'IMAGE') {
              item.image = form.questionnaireField.image;
              item.imageTitle = form.questionnaireField.imageTitle;
            } else if (type === 'VIDEO') {
              item.videoImage = form.questionnaireField.videoImage;
              item.videoLink = form.questionnaireField.videoLink;

              item.videoTitle = form.questionnaireField.videoTitle;
            } else if (type === 'TEXT') {
              item.fieldTips = form.questionnaireField.fieldTips;
              item.editorValue = form.questionnaireField.editorValue;
            } else if (type === 'AGENDA') {
              item.agendaName = form.questionnaireField.agendaName;
              item.agendaDate = form.questionnaireField.agendaDate;
              item.agendaTitle = form.questionnaireField.agendaTitle;
            }
          }
        });
      },
      handleFilterOption(inputValue, option) {
        return option.key.campaignName.indexOf(inputValue) >= 0;
      },

      handleChangeText: () => {},
      handleTime: () => {
        let start = form.questionnaireField.startTime;
        let endTime = form.questionnaireField.endTime;
        //时间戳
        const startTamp = Date.parse(start);
        const endTimeTamp = Date.parse(endTime);

        if (startTamp > endTimeTamp) {
          form.questionnaireField.endTime = '';
          message.error('结束时间不能小于开始时间');
        }
      },

      handleSave: () => {
        validate?.value().then(() => {
          // 处理议程属性必填问题
          const agendaList = store.state.common.questionnaireFields.filter(
            item => item.componentType === 'AGENDA'
          );
          if (agendaList[0]?.agendaName !== '') {
            if (agendaList.length > 0) {
              for (let i = 0; i < agendaList.length; i++) {
                // 处理议程时间不能重复
                let list = agendaList[i].questionAnswerVoList;

                if (list.length === 0) {
                  return message.error('必须配置议程内容！');
                }
                // else if (list.length > 0) {
                //   let uniqueTimeRanges = new Set(); // 使用 Set 来存储已经出现过的时间范围
                //   // filteredS 数组中将只包含去重后的元素。
                //   let filteredS = [];
                //
                //   for (let event of list) {
                //     let timeRange = event.startTime + '-' + event.endTime;
                //
                //     if (!uniqueTimeRanges.has(timeRange)) {
                //       // 如果 Set 中不包含这个时间范围
                //       uniqueTimeRanges.add(timeRange); // 将时间范围添加到 Set 中
                //       filteredS.push(event); // 将当前事件添加到去重后的数组中
                //     }
                //   }
                //
                //   // 拿去重的数组长度比较来判断数组是否存在相同的值
                //   if (list.length > filteredS.length) {
                //     return message.error('请勿设置相同的议程时间！');
                //   }
                // }

                // 处理议程时间结束时间小于开始时间
                for (let i = 0; i < list.length; i++) {
                  const element = list[i];
                  const startTimeDate = new Date(
                    `1970-01-01T${element.startTime}:00`
                  );
                  const endTimeDate = new Date(
                    `1970-01-01T${element.endTime}:00`
                  );

                  if (endTimeDate < startTimeDate) {
                    return message.error('议程结束时间不能小于或等于开始时间');
                  }

                  if (element.editorValue === '') {
                    return message.error('议程内容不能为空！');
                  }

                  // childGroup 数组
                  // let childGroupList = list[i].childGroup;
                  // for (let i = 0; i < childGroupList.length; i++) {
                  //   console.log('childGroupList[i].editorValue', childGroupList[i].editorValue.length, childGroupList[i].editorValue)
                  //   if (childGroupList[i].editorValue === '') {
                  //     return message.error('议程演讲主题不能为空！');
                  //   }
                  // }
                }

                if (!agendaList[i].startTime) {
                  return message.error('议程开始时间不能为空！');
                } else if (!agendaList[i].endTime) {
                  return message.error('议程结束时间不能为空！');
                } else {
                  continue;
                }
                //  else if (!agendaList[i].name) {
                //   return message.error('议程主讲人不能为空！');
                // }
              }
            }
          }

          // 处理嘉宾属性必填问题
          const angerList = store.state.common.questionnaireFields.filter(
            item => item.componentType === 'ANGER'
          );
          if (angerList.length > 0) {
            let questionAnswerVoList = angerList[0]?.questionAnswerVoList;
            if (questionAnswerVoList.length === 0) {
              return message.info('请输入嘉宾内容！');
            }
            if (questionAnswerVoList.length > 0) {
              for (let i = 0; i < questionAnswerVoList.length; i++) {
                if (questionAnswerVoList[i].guestsBriefly === '') {
                  return message.info('嘉宾简介不能为空');
                } else if (questionAnswerVoList[i].guestsImage === '') {
                  return message.info('嘉宾图片不能为空');
                }
                // else if (questionAnswerVoList[i].guestsName === '') {
                //   return message.info('嘉宾姓名不能为空');
                // }
              }
            }
          }

          // 处理banner 链接
          let bannerLink = store.state.common.bannerChart.bannerLink;
          if (bannerLink) {
            if (!validHttp(bannerLink)) {
              return message.error(
                '请输入正确的横幅视频URL地址，以http或者https开头的链接'
              );
            }
          }

          let parmas = {
            banner: store.state.common.bannerChart,
            title: store.state.common.questionnaire,
            content: store.state.common.questionnaireFields,
          };
          const { activityId, id, saveId } = store.state.common;
          if (id) {
            // 编辑
            activityCenterApi
              .updateActivityCenter('', {
                json: JSON.stringify(parmas),
                activityId: activityId,
                id: saveId ? saveId : id,
                formId: local.get('formId'),
              })
              .then(res => {
                store.commit('common/setSaveId', res.id);
                message.success('保存成功');
                // router.go(-1);
                store.commit('common/setStatus', false);
              });
          } else {
            // 新增
            activityCenterApi
              .activitySave('', {
                json: JSON.stringify(parmas),
                activityId: activityId,
                formId: local.get('formId'),
              })
              .then(res => {
                store.commit('common/setId', res.id);
                store.commit('common/setSaveId', res.id);
                message.success('保存成功');
                // router.go(-1);
                store.commit('common/setStatus', false);
              });
          }
        });
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-right {
  padding: 10px;
  .right-form {
    margin-top: 14px;
    :deep(.ant-form-item-label label) {
      font-size: 16px;
    }
  }
}
.backColor {
  display: flex;
  .backColorText {
    margin-right: 48px;
  }
}
.main-content {
  height: 100%;
  border-radius: 8px;
  box-shadow: @box-shadow-base;
}
.main-ckeditor {
  height: calc(100vh - 203px);
}
.main-button {
  margin-left: 550px;
  padding: 10px;
  text-align: right;
}
.iss-but {
  box-sizing: border-box;
  border: 1px dashed #5979f7;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  .but {
    padding: 0px 50px;
  }
}
.iss-but2 {
  box-sizing: border-box;
  border: 1px dashed #5979f7;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .but {
    padding: 0px 30px;
  }
}
.tips {
  color: #bfbfbf;
  .icon {
    margin-right: 3px;
  }
}
.creatForm {
  margin-top: 5px;
}
.creatFormTips {
  color: #bfbfbf;
  margin-top: 2px;
}
</style>
